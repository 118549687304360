<template>
  <div class="p-grid p-fluid">

    <!-- Collab Data   -->
    <div class="card p-col p-mr-1">
      <div class="p-col-3">
        <a href="#" @click.prevent="backToIndex">Ir a lista</a>
      </div>

      <div class="p-col-12">
        <div>
          <div class="p-mr-1 p-d-inline">
            <label style="font-size: 24px">Colaboración {{ collab?.getTypeString() }} <a href="#">{{ collab?.data.code }}</a></label>
          </div>

          <div v-if="collab?.isActive()" class="p-d-inline">
            <Chip label="Activa" class="p-mr-2 p-mb-2 active-collab"/>
          </div>

          <div v-else-if="collab?.isCanceled()" class="p-d-inline">
            <Chip label="Cancelada" class="p-mr-2 p-mb-2 canceled-collab"/>
          </div>

          <div v-else-if="collab?.isClosed()" class="p-d-inline">
            <Chip label="Cerrada" class="p-mr-2 p-mb-2 closed-collab"/>
          </div>
        </div>


        <div v-if="collab?.isSpecificDay()" style="font-size: 18px">
          <label>Para la fecha: </label>
          <strong>{{ collab?.start_at.format('Y-MM-DD HH:mm') }}</strong>
        </div>

        <div v-else style="font-size: 18px">
          <label>Fechas: </label>
          Desde {{ collab?.start_at.format('Y-MM-DD') }} al {{ collab?.end_at.format('Y-MM-DD') }}
        </div>

        <h5>Configuraciones</h5>
        <ul>
          <li>
            Es una foodie quedada: {{ collab?.isFoodieMeeting() ? 'Si' : 'No' }}
          </li>
          <li>
            Limite de acompañantes: {{ collab?.data.companions_limit }}
          </li>

          <li>
            Persona de contacto: {{ collab?.data.contact_name }}
          </li>

          <li>
            Numero de contacto: {{ collab?.data.contact_phone }}
          </li>

          <li>
            Descuento: {{ collab?.getDiscountValueString() }}
          </li>

          <li>
            Usos: {{ collab?.data.uses }}
          </li>

          <li v-if="collab?.isPublicType()">
            Limite de usos: {{ collab?.data.uses_limit }}
          </li>

          <li v-if="collab?.data.min_ig_followers">
            Mínimo de seguidores: {{ collab?.data.min_ig_followers }}
          </li>
        </ul>

        <h5>Timestamps</h5>
        <ul>
          <li>
            Creado el: {{collab?.created_at.format('Y-MM-DD LTS')}}
          </li>

          <li>
            Ultima actualización: {{collab?.updated_at.format('Y-MM-DD LTS')}}
          </li>

          <li v-if="collab?.isClosed()">
            Cerrado el: {{collab?.closed_at.format('Y-MM-DD LTS')}}
          </li>

          <li v-if="collab?.isCanceled()">
            Cancelado el: {{collab?.canceled_at.format('Y-MM-DD LTS')}}
          </li>

        </ul>

      </div>

      <div v-if="collab?.isCanceled()" class="p-col-12 p-mb-2">
        <Button label="Reactivar collab" @click="activateCollab"/>
      </div>

      <div class="p-col-12">
        <Button label="Editar" @click="toEditCollab"/>
      </div>
    </div>

    <!-- Rest Data -->
    <div class="card p-col">
      <div class="p-col-12">
        <h4>Restaurante</h4>

        <div v-if="restaurant?.hasInstagram()" class="p-d-inline">
          <a :href="restaurant.getIgLink()" target="_blank">{{restaurant.getIgString()}}</a>
        </div>

        <div v-else class="p-d-inline">
          {{restaurant?.data.name}}
        </div>

        <div v-if="restaurant?.isVerified()" class="p-d-inline">
          <Chip label="Verificado" class="p-mr-2 p-mb-2 active-collab"/>
        </div>

        <div v-else>
          <Chip label="No verificado" class="p-mr-2 p-mb-2 p-mt-2"/>
        </div>

        <div class="p-mt-4 p-col-12 p-p-0">
          <h4 class="p-col-10 p-d-inline-block">Requerimientos</h4>
          <div class="p-col-2 p-d-inline-block">
            <Button :label="!editingRequirements ? 'Editar' : 'Cancelar'" @click="editingRequirements = !editingRequirements"/>
          </div>
          <div v-if="!editingRequirements" class="p-mt-2 p-col-12">
            {{ collab?.data?.requirements ? collab?.data?.requirements : 'Sin requerimientos' }}
          </div>
          <div v-else class="p-mt-2 p-col-12">
            <div class="p-text-right">
              <p>{{ requirements.length + '/ 400' }}</p>
            </div>
            <Textarea
                rows="4"
                v-model="requirements"
                :autoResize="true"
                maxlength="400"
                class="p-col-12 p-mb-2"
            >
              (opcional)
            </Textarea>
            <Button label="Actualizar" @click="updateRequirements"></Button>
          </div>

        </div>

      </div>
    </div>

    <!-- Foodies Data   -->
    <div class="card p-col-12">
      <div class="p-col-12">
        <h4>
          Foodies
        </h4>

        <div v-if="foodies.length > 0">
          <ul>
            <li v-for="(foodie) in foodies" :key="foodie.data.id">

              <div class="p-mr-2 p-d-inline">
                <span v-if="foodie.hasInstagram()">
                  <a :href="foodie.getIgLink()" target="_blank">{{ foodie.getIgString() }}</a>
                </span>

                <span v-else>
                  {{ foodie.data.name }}
                </span>
              </div>

              <Chip :label="foodie.pivot.getStatusString()" class="p-mr-2 p-mb-2"/>

              <Chip v-if="foodie.pivot.restaurantIsRated()" label="Ya rateo" class="p-mr-2 p-mb-2"/>
              <Chip v-else label="Pendiente por ratear" class="p-mr-2 p-mb-2"/>

            </li>
          </ul>
<!--          {{ foodieList }}-->
          <DataTable :value="foodieList" responsiveLayout="scroll">
            <Column style="">
              <template #body="{data}" class="p-p-0">
                <div v-if="data.booking && data.booking.status === 'active'">
                  <div v-if="data.booking.uses_provider" class="bgYellow" style="display: block; margin: 0 auto; width: 1.2rem;height: 1.2rem;border-radius: 1rem"/>
                  <div v-else class="bgGreen" style="display: block; margin: 0 auto; width: 1.2rem; height: 1.2rem;border-radius: 1rem"/>
                </div>
                <div v-else style="width: 1.2rem"/>
              </template>
            </Column>
            <Column field="name" header="Nombre" />
            <Column field="ig_username" header="Ig Username" />
            <Column field="email" header="Email" />
            <Column field="phone" header="Teléfono" />
<!--            <Column field="pivot.status" header="Estatus" />-->
            <Column header="Estatus">
              <template #body="{data}">
<!--                {{ data.pivot.status }}-->
                <Chip :label="data.pivot.status" class="p-mr-2 p-mb-2"/>
              </template>
            </Column>
            <Column header="Reservado">
              <template #body="{data}">
                <!--                {{ data.pivot.status }}-->
                <div v-if="data.booking && ['active', 'pending', 'confirmed_by_provider'].includes(data.booking.status)">
                  <p :class="data.booking.uses_provider ? 'bookingYellow' : 'bookingGreen'" class="p-text-bold">{{ data.booking.date +' ' + data.booking.time }}</p>
                </div>
                <div v-else>
                  <p class="bookingRed p-text-bold">No</p>
                </div>
<!--                <p :class="data.booking ? 'bookingGreen' : 'bookingRed'" class="p-text-bold">{{ data.booking ? data.booking.date +' ' + data.booking.time : 'No' }}</p>-->
<!--                <p :class="data.pivot.booking !== null ? 'bookingGreen' : 'bookingRed'" class="p-text-bold">No</p>-->
<!--                <Chip :label="data.pivot.booking_id ? 'Si' : 'No'" class="p-mr-2 p-mb-2"/>-->
<!--                <p>{{ data.booking ? data.booking.date : 'no' }}</p>-->
              </template>
            </Column>
            <Column header="Opciones">
              <template #body="{data}">
<!--                <Button-->
<!--                    v-if="((data.pivot.status === 'accepted' &&-->
<!--                    !data.pivot.booking_id) ||-->
<!--                    (data.pivot.status === 'accepted' &&-->
<!--                    data.booking.status === 'canceled')) && !collab?.isFoodieMeeting()" type="button" icon="pi pi-cog" class="p-button-rounded p-button-outlined"-->
<!--                    @click="toggleActionMenu($event, data)" aria-haspopup="true"-->
<!--                    aria-controls="overlay_menu"/>-->
                <Button
                    type="button" icon="pi pi-cog" class="p-button-rounded p-button-outlined"
                    @click="toggleActionMenu($event, data)" aria-haspopup="true"
                    aria-controls="overlay_menu"/>
                <Menu id="overlay_menu" ref="action_menu" :model="actionMenuItems" :popup="true"/>
              </template>
            </Column>
<!--              <template #body="{ foodie }">-->
<!--&lt;!&ndash;                <p>{{ foodie.getName() }}</p>&ndash;&gt;-->
<!--              </template>-->

<!--            <Column field="code" header="Code"></Column>-->
<!--            <Column field="name" header="Name"></Column>-->
<!--            <Column field="category" header="Category"></Column>-->
<!--            <Column field="quantity" header="Quantity"></Column>-->
          </DataTable>
        </div>

        <div v-else>
          Sin foodies!
        </div>

      </div>
    </div>
    <Dialog header="Crear reserva" v-model:visible="reservationModal" :style="{width: '50vw'}"
            :modal="true">

      <div class="p-fluid p-grid formgrid">
        <div class="field p-col-6">
          <label for="date">Fecha de reserva</label>
          <Calendar id="date" class="p-mt-2" v-model="date" autocomplete="off" />
        </div>
        <div class="field p-col-6">
          <label for="time">Hora de reserva</label>
          <Calendar id="time" class="p-mt-2" v-model="time" :timeOnly="true" hourFormat="12" />
        </div>
        <div class="field p-col-6">
          <label for="people">Cantidad de personas</label>
          <Dropdown id="people" class="p-mt-2" v-model="peopleAmount" :options="people" optionLabel="name" optionValue="value"/>
        </div>

<!--        <div class="p-align-center">-->
<!--          <label for="date">Fecha de reservación</label>-->
<!--          <input id="date" type="date" v-model="date" class="p-mt-2">-->
<!--        </div>-->
<!--        <div class="p-align-center">-->
<!--          <label for="time">Hora de reserva</label>-->
<!--          <input id="time" type="time" v-model="time" class="p-mt-2">-->
<!--        </div>-->
      </div>
      <!--      <form action="">-->
      <!--        <div class="p-field">-->
      <!--          <div class="p-text-center">-->
      <!--            <label for="invitations_qty" class="p-d-block p-mb-2">Cantidad de invitaciones</label>-->
      <!--            <InputNumber ref="invitationsQtyInput" id="invitations_qty" v-model="invitationsQuantity" mode="decimal"-->
      <!--                         :useGrouping="false"-->
      <!--                         :min="1"/>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </form>-->

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="reservationModal = false" class="p-button-text"/>
        <Button label="Confirmar" icon="pi pi-check" @click="createReservation()" autofocus/>
      </template>
    </Dialog>
    <Toast />
  </div>
</template>

<script>
import Collab from "src/repositories/models/Collab";
import Foodie from "../../repositories/models/Foodie";
import Restaurant from "../../repositories/models/Restaurant";
import config from "src/config";
import moment from "moment";

export default {
  data() {
    return {
      debug: config.DEBUG,
      collab: null,
      foodies: [],
      foodieList: [],
      bookings: [],
      actionFoodie: {},
      restaurant: null,
      actionMenuItems: [],
      reservationModal: false,
      date: '',
      time: '',
      peopleAmount: 1,
      query: {
        'with': 'slimRestaurant,foodies,bookings',
      },
      people: [
        {name: '1', value: 1},
        {name: '2', value: 2},
        {name: '3', value: 3},
        {name: '4', value: 4},
        {name: '5', value: 5},
        {name: '6', value: 6},
        {name: '7', value: 7},
        {name: '8', value: 8}
      ],
      requirements: '',
      oldValue: '',
      editingRequirements: false
    }
  },
  methods: {
    createReservation () {
      const formattedDate = moment(this.date).format('YYYY-MM-DD')
      const formattedTime = moment(this.time).format('HH:mm')
      // console.log(moment(this.date).format('YYYY-MM-DD'))
      // console.log(this.$route.params.id)
      this.$repository.bookings
        .store(this.actionFoodie.id, {
          collaboration_id: this.$route.params.id,
          date: formattedDate + ' ' + formattedTime,
          people_amount: this.peopleAmount,
          use_booking_provider: false
        })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: `Se ha creado la reserva con exito`,
            life: 2000
          })
          this.reservationModal = false
          this.getCollab()
        })
        .catch((data) => {
          let summary = 'Error al obtener colaboración'
          let detail = null

          if ('response' in data) {
            summary = data.response.data?.message
            detail = data.response.data?.description
          }
          this.$toast.add({
            severity: 'error',
            summary,
            detail,
            life: 3000
          });
        })
        // .finally(() => {
        //   this.reservationModal = false
        // })
    },
    updateRequirements() {
      this.$repository.collaborations
        .update(this.$route.params.id, {requirements: this.requirements})
        .then(() => {
          this.getCollab()
          this.editingRequirements = false
        })
    },
    async getCollab() {
      try {
        const response = await this.$repository.collaborations.show(
            this.$route.params.id, this.query
        )

        this.collab = new Collab(response.data)

        this.foodies = []
        this.bookings = []

        if ('foodies' in response.data) {
          response.data.foodies.forEach(data => {
            this.foodies.push(new Foodie(data))
          });
        }

        if ('bookings' in response.data) {
          response.data.bookings.forEach(data => {
            this.bookings.push(data)
          });
        }

        this.requirements = response.data.requirements ? response.data.requirements : ''
        this.oldValue = this.requirements

        this.foodieList = response.data.foodies
        this.foodieList = this.foodieList.map((foodie) => {
          let foodieBooking = null
          this.bookings.forEach((booking) => {
            if (booking.foodie_id === foodie.id) {
               foodieBooking = booking
            }
          })
          if (foodieBooking) {
            return { ...foodie, booking: foodieBooking }
          } else {
            return { ...foodie }
          }
        })

        console.log(this.foodieList)

        if ('slim_restaurant' in response.data) {
          this.restaurant = new Restaurant(response.data.slim_restaurant, true)
        } else if ('restaurant' in response.data) {
          this.restaurant = new Restaurant(response.data.restaurant)
        }

      } catch (err) {
        let summary = 'Error al obtener colaboración'
        let detail = null

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 1000});
      }
    },
    toggleActionMenu(event, foodie) {
      this.actionFoodie = foodie;

      this.actionMenuItems = [
        {
          label: 'Crear reserva',
          command: () => {
            this.date = ''
            this.time = ''
            this.peopleAmoun = 1
            this.reservationModal = true
          }
        }
      ]
      this.$refs.action_menu.toggle(event)
    },
    backToIndex() {
      this.$router.push('/collaborations')
    },
    async activateCollab() {

      if (!confirm('Seguro desea reactivar esta colaboración cancelada?')) {
        return;
      }

      try {
        await this.$repository.collaborations.activateCollab(this.collab.data.id)

      } catch (err) {
        let summary = 'Error al activar la colaboración'
        let detail = null

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 1000});
      }

      this.$toast.add({
        severity: 'success',
        summary: `La colaboracion ha sido reactivada`,
        life: 1000
      });

      await this.getCollab();
    },
    toEditCollab() {
      this.$router.push(`/collaborations/${this.collab.data.id}/edit`)
    },
  },
  mounted() {
    this.getCollab();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.name === from.name) {
        this.getCollab();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.p-chip.active-collab {
  background: green;
  color: var(--primary-color-text);
}

.p-chip.canceled-collab {
  background: red;
  color: var(--primary-color-text);
}

.p-chip.closed-collab {
  background: gray;
  color: var(--primary-color-text);
}
.bookingRed {
  color: #e82c2c;
}
.bookingGreen {
  color: #27b327;
}
.bookingYellow {
  color: #eec91b;
}
.bgYellow{
  background: #eec91b;
}
.bgGreen{
  background: #27b327;
}
</style>
